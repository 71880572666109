import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BasicServerError, ErrorResponse, ServerError, isErrorResponse } from "./common/error";
import { catchErrorOfType } from "./common/rxjs-utilities";
import { ValidationResultError } from "./common/validation-result";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchErrorOfType(HttpErrorResponse, (e) => {
                const body = this.parseError(e.error);
                if (isErrorResponse(body)) {
                    throw this.errorFromErrorReponse(body);
                } else if (typeof body === "string") {
                    throw new Error(body);
                } else {
                    throw e as Error;
                }
            }),
        );
    }

    private errorFromErrorReponse(errorResponse: ErrorResponse) {
        if (ValidationResultError.matchingResponse(errorResponse)) {
            return new ValidationResultError(errorResponse);
        } else if (BasicServerError.matchingResponse(errorResponse)) {
            return new BasicServerError(errorResponse);
        } else {
            return new ServerError(errorResponse);
        }
    }

    private parseError(error: unknown): unknown {
        if (typeof error === "string") {
            try {
                return JSON.parse(error);
            } catch (e) {
                return error;
            }
        }

        return error;
    }
}
