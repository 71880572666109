import {
    HTTP_INTERCEPTORS,
    HttpClient,
    provideHttpClient,
    withInterceptorsFromDi,
} from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { MAT_CARD_CONFIG, MatCardConfig } from "@angular/material/card";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { AppDialogModule } from "app/common-ux/dialog/dialog.module";
import { AppConfig } from "app/common/app-config";
import { combineLatest, switchMap } from "rxjs";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AppService } from "./app.service";
import { AppAuthModule } from "./auth/auth.module";
import { AuthenticationService } from "./auth/authentication.service";
import { provideExistingUserObserver } from "./auth/user-observer";
import { provideExistingDialogObserver } from "./common-ux/dialog/dialog-observer";
import { HeapAnalytics } from "./heap-analytics";
import { HttpErrorInterceptor } from "./http-error.interceptor";
import { SentryLogger } from "./sentry-logger";
import { AppTenantModule } from "./tenant/module/tenant.module";
import { TenantService } from "./tenant/module/tenant.service";

@NgModule({
    declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,

        MatProgressSpinnerModule,

        AppDialogModule,
        AppAuthModule,
        AppTenantModule,
        // Must be last routing module so the catch-all route doesn't trigger before the real ones
        AppRoutingModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {
            provide: LOCALE_ID,
            useValue: "en-AU",
        },
        {
            provide: MAT_CARD_CONFIG,
            useValue: {
                appearance: "outlined",
            } as MatCardConfig,
        },
        {
            provide: APP_INITIALIZER,
            deps: [AppConfig],
            useFactory: (appConfig: AppConfig) => {
                // Has to return a promise: https://github.com/angular/angular/issues/15088
                return () => appConfig.config$.toPromise();
            },
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: HttpErrorInterceptor,
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        provideExistingUserObserver(HeapAnalytics),
        provideExistingUserObserver(SentryLogger),
        provideExistingDialogObserver(HeapAnalytics),
        provideExistingDialogObserver(SentryLogger),
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {
    public constructor(
        authenticationService: AuthenticationService,
        tenantService: TenantService,
        appService: AppService,
        httpClient: HttpClient,
        appConfig: AppConfig,
        _traceService: Sentry.TraceService, // Force initialisation
    ) {
        void authenticationService.checkForCurrentSession();

        // Prime the server for requests (if we've scaled to zero)
        appConfig
            .serverEndpoint("info")
            .pipe(switchMap((url) => httpClient.get(url)))
            .subscribe();

        combineLatest([
            tenantService.currentTenant$,
            appService.pageTitle$,
        ]).subscribe(([tenant, pageTitle]) => {
            let title = pageTitle ?? "";
            if (title) {
                title += " | ";
            }
            title += tenant ? tenant.name : "Orchestrate";
            document.title = title;
        });
    }
}
