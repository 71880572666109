import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { Observable } from "rxjs";
import { filter, first, map, shareReplay } from "rxjs/operators";
import { User } from "./auth/user";
import { UserObserver } from "./auth/user-observer";
import { DialogObserver } from "./common-ux/dialog/dialog-observer";
import { AppConfig, Environment } from "./common/app-config";
import { BreezeEntity } from "./dal/breeze-entity";

@Injectable({
    providedIn: "root",
})
export class SentryLogger implements UserObserver, DialogObserver {
    private isSentryConfigured = false;
    private _sentry$: Observable<typeof Sentry>;

    public constructor(private appConfig: AppConfig) {
        this._sentry$ = this.appConfig.config$.pipe(
            map((config) => {
                this.isSentryConfigured = !!config.sentryDsn;
                if (this.isSentryConfigured) {
                    Sentry.init({
                        dsn: config.sentryDsn,
                        // We should already see errors in the console locally!
                        enabled: config.environmentName !== Environment.Development,
                        environment: config.environmentName,
                        // Will need to revisit this as we grow
                        tracesSampleRate: 0.5,
                        tracePropagationTargets: [config.serverEndpoint],
                        integrations: [
                            Sentry.browserTracingIntegration(),
                        ],
                    });
                }

                return Sentry;
            }),
            shareReplay(1),
        );
    }

    private get sentry$() {
        return this._sentry$.pipe(
            first(),
            filter(() => this.isSentryConfigured),
        );
    }

    public onUserChanged(user: User | undefined) {
        this.sentry$.subscribe((sentry) => {
            const sentryUser: Sentry.User | null = user
                ? { id: user.id, username: user.username }
                : null;
            sentry.setUser(sentryUser);
        });
    }

    // Leverage existing categories for these events
    // https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types

    public onDialogOpened(dialogName: string, inputData: unknown): void {
        this.sentry$.subscribe((sentry) => {
            sentry.addBreadcrumb({
                category: "ui.dialog-opened",
                message: dialogName,
                data: {
                    inputData: this.generateBreadcrumbDataFromDialogData(inputData),
                },
            });
        });
    }

    public onDialogClosed(dialogName: string, resolveData: unknown): void {
        this.sentry$.subscribe((sentry) => {
            sentry.addBreadcrumb({
                category: "ui.dialog-closed",
                message: dialogName,
                data: {
                    resolveData: this.generateBreadcrumbDataFromDialogData(resolveData),
                },
            });
        });
    }

    public onDialogCancelled(dialogName: string): void {
        this.sentry$.subscribe((sentry) => {
            sentry.addBreadcrumb({
                category: "ui.dialog-cancelled",
                message: dialogName,
            });
        });
    }

    // TODO Make this a util + tests
    private generateBreadcrumbDataFromDialogData(data: unknown): unknown {
        return this.generateBreadcrumbDataFromDialogDataRecurse(data, new Set<object>());
    }

    private generateBreadcrumbDataFromDialogDataRecurse(
        data: unknown,
        seenObjects: Set<object>,
    ): unknown {
        if (seenObjects.has(data as object)) {
            return "<Circular Reference>";
        } else if (typeof data === "string") {
            return data.length > 100 ? `${data.substr(0, 100)}...` : data;
        } else if (data instanceof BreezeEntity) {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            return `${data.entityType.shortName}[${data.entityAspect.getKey().values}]`;
        } else if (Array.isArray(data)) {
            seenObjects.add(data);
            return data.map((d) =>
                this.generateBreadcrumbDataFromDialogDataRecurse(d, seenObjects),
            );
        } else if (typeof data === "object" && !!data) {
            seenObjects.add(data);
            const d = data as Record<string, unknown>;
            return Object.keys(data).reduce<Record<string, unknown>>((obj, key) => {
                obj[key] = this.generateBreadcrumbDataFromDialogDataRecurse(d[key], seenObjects);
                return obj;
            }, {});
        } else {
            return data;
        }
    }
}
